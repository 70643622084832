<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5">
      <div class="p-4 flex">
        <h1 class="text-3xl">Lista de aceites - {{ term.title }}</h1>
      </div>
      <div class="p-4">
        <div class="flex mb-4 justify-between">
          <router-link
            class="bg-green-1000 text-white px-6 py-3 rounded rounded-md focus:outline-none"
            :to="{ name: 'list-terms' }"
          >
            <svg
              style="display: initial;"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
            Voltar
          </router-link>
        </div>
        <v-client-table
          ref="table"
          :data="accepts"
          :placement="left"
          :columns="columns"
          :options="options"
        >
          <div slot="name" slot-scope="{ row }">
            <the-mask
              class="text-center outline-none bg-transparent"
              :value="row.user.name"
              readonly
            ></the-mask>
          </div>
          <div slot="user" slot-scope="{ row }">
            {{ row.user.name }}
          </div>
          <div slot="email" slot-scope="{ row }">
            {{ row.user.email }}
          </div>
          <div slot="accept" slot-scope="{ row }">
            {{ row.accepted_at }}
          </div>
          <div slot="profile" slot-scope="{ row }">
            {{ row.user.role.label }}
          </div>
        </v-client-table>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';
import axios from '@/utils/axios';
import { TheMask } from 'vue-the-mask';

export default {
  name: 'Index',

  props: {
    id: {
      type: String,
      required: true
    }
  },

  title() {
    return `${process.env.VUE_APP_NAME} | Lista Aceites`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation,
    TheMask
  },

  data() {
    return {
      term: {
        title: ''
      },
      isLoading: false,
      accepts: [],
      titles: '',
      columns: ['id', 'user', 'email', 'accept', 'profile'],
      options: {
        sortable: true,
        perPage: 10,
        perPageValues: [10, 15, 20, 25, 50],
        headings: {
          user: 'Nome Usuário',
          email: 'E-mail',
          accept: 'Data Aceite',
          profile: 'Perfil Acesso'
        },
        hiddenColumns: ['id'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          first: 'Primeiro',
          last: 'Último',
          filter: 'Filtrar:',
          filters: 'Filtrar:',
          filterBy: 'Filtrar por {column}',
          filterPlaceholder: 'Pesquise aqui...',
          count:
            '{from} - {to} de {count} registros|{count} registros|Um registro',
          limit: 'Registros por página:',
          page: 'Página:',
          noResults: 'Nenhum registro encontrado.',
          columns: 'Colunas'
        },
        pagination: {
          chunk: 5
        }
      }
    };
  },
  methods: {
    async fetchAccepts() {
      await axios.get(`/api/aceites/${this.id}/usuarios`).then(({ data }) => {
        data.data.forEach(accept => {
          this.accepts.push(accept);
        });
      });
      this.isLoading = false;
    },
    fetchTerm() {
      this.isLoading = true;
      axios.get(`/api/termos/${this.id}`).then(({ data }) => {
        this.term.title = data.data.title;
      });
    }
  },
  created: function() {
    this.fetchTerm();
    this.fetchAccepts();
  }
};
</script>

<style scoped></style>
